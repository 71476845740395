import ImageFileService from 'services/image-file/image.service';
import AssetHelper from 'helpers/asset.helper';
import { AssetEditorComponentMapKeys } from '../interfaces/AssetEditorComponentMap';

/**
 * Helper class to handle asset editor helper functions.
 */
class AssetEditorHelper {
    /**
     * Gets the modified asset src if it's not undefined else returns the original asset src.
     * @param originalAssetSrc Original asset src is not expected to be changed.
     * @param modifiedAssetSrc Modified asset src.
     * @returns asset src as a string type.
     */
    static getAssetUrl(originalAssetSrc: string, modifiedAssetSrc?: string, previewAssetSrc?: string) {
        if (previewAssetSrc && previewAssetSrc !== '') {
            return previewAssetSrc;
        } else if (modifiedAssetSrc && modifiedAssetSrc !== '') {
            return modifiedAssetSrc;
        }

        return originalAssetSrc;
    }

    /**
     * Gets a Promise that resolves to an HTMLImageElement with the specified image source.
     * @param imageSrc - The URL of the image to load.
     * @returns A Promise that resolves to an HTMLImageElement with the specified image source.
     */
    static getImageElement = (imageSrc: string): Promise<HTMLImageElement> => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const image = new window.Image();
            image.crossOrigin = 'anonymous'; // Set the crossOrigin attribute

            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);

            image.src = imageSrc;
        });
    };

    /**
     * Returns the key for the component to be rendered in the AssetEditor based on the given parameters.
     * @param canSkipCropper - Whether the cropper component can be skipped or not.
     * @param useCompressor - Whether to use the image compressor component.
     * @param useCropper - Whether to use the image/video cropper component.
     * @param fileType - The type of the file being edited ('image' or 'video'). Keep in mind filetype for image is returned as array of image types.
     * @returns The key for the component to be rendered.
     */
    static getComponentKey = (
        canSkipCropper: boolean | undefined,
        useCropper: boolean | undefined,
        extension: string,
        outputWidth: number,
        outputHeight: number,
        initComponentKey?: AssetEditorComponentMapKeys
    ): AssetEditorComponentMapKeys => {
        if (initComponentKey) {
            return initComponentKey;
        }
        // Show cropper if asset must be cropped.
        if ((useCropper && !canSkipCropper) || ((outputWidth || outputHeight) && !canSkipCropper)) {
            return AssetHelper.getFileType(extension) === 'video' ? 'videoCropper' : 'imageCropper';
        }

        return 'previewAsset';
    };

    /**
     * Returns the image format of a base64 encoded image.
     * @param base64 - The base64 encoded image.
     * @returns The image format (e.g. 'jpeg', 'png', 'gif').
     */
    static getImageFormat = (base64: string) => {
        const format = ImageFileService.getImageFormatFromBase64(base64);

        // canvas.toDataURL can only handle 'jpeg'
        if (format === 'jpg') {
            return 'jpeg';
        }

        return format;
    };

    /**
     * Returns a Promise that resolves to a blob URL of the canvas image.
     * @param canvas - The canvas element to get the blob from.
     * @param imageSrc - The source URL of the image.
     * @returns A Promise that resolves to a blob URL of the canvas image.
     */
    static getBlobFromCanvas = (canvas: HTMLCanvasElement, imageSrc: string, imageQuality: number): Promise<Blob | null> => {
        return new Promise((resolve) => {
            const imageFormat = ImageFileService.getImageFormatFromBase64(imageSrc);

            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                `image/${imageFormat}`,
                imageQuality
            );
        });
    };

    /**
     * Converts a Blob object to a base64-encoded string.
     * @param blob The Blob object to convert.
     * @returns A Promise that resolves with the base64-encoded string or empty string if the blob is null.
     */
    static convertBlobToBase64 = async (blob: Blob): Promise<string> => {
        return await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = () => {
                const results = reader.result;

                if (typeof results === 'string') {
                    resolve(results);
                } else {
                    resolve('');
                }
            };
        });
    };
}

export default AssetEditorHelper;
