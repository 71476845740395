import React, { useCallback, useEffect, useState } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Setup from 'components/data/Setup';
import Translation from 'components/data/Translation';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AssetHelper from 'helpers/asset.helper';
import AssetEditorSidebarRow, { AssetEditorSidebarRowProps } from './components/asset-editor-side-bar-row';
import assetEditorSidebarRows from '../../data/side-bar-rows';
import { AssetEditorComponentMapKeys } from '../../interfaces/AssetEditorComponentMap';
import AssetEditorState from '../../interfaces/AssetEditorState';

import './styles/main.scss';

interface AssetEditorSidebarState {
    canRemoveBackground: AssetGalleryDialogState['conditionProps']['canRemoveBackground'];
    canMagicEdit: AssetGalleryDialogState['conditionProps']['canMagicEdit'];
    canOutpaint: AssetGalleryDialogState['conditionProps']['canOutpaint'];
    canRemoveObjects: AssetGalleryDialogState['conditionProps']['canRemoveObjects'];
    value: AssetGalleryDialogState['value'];
    useCropper: AssetGalleryDialogState['conditionProps']['useCropper'];
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    canSkipCropper: AssetGalleryDialogState['conditionProps']['canSkipCropper'];
    maxOutputWidth: AssetGalleryDialogState['data']['assetData']['maxOutputWidth'];
    maxOutputHeight: AssetGalleryDialogState['data']['assetData']['maxOutputHeight'];
}

/**
 * A sidebar component for the asset editor that displays transform options and smart AI tools.
 */
const AssetEditorSidebar: React.FC = () => {
    const {
        canRemoveBackground,
        canMagicEdit,
        canOutpaint,
        canRemoveObjects,
        value,
        useCropper,
        outputWidth,
        outputHeight,
        canSkipCropper,
        maxOutputWidth,
        maxOutputHeight
    } = useComponentStore<AssetEditorSidebarState>('AssetGalleryDialog', {
        fields: {
            canRemoveBackground: 'conditionProps.canRemoveBackground',
            canMagicEdit: 'conditionProps.canMagicEdit',
            canOutpaint: 'conditionProps.canOutpaint',
            canRemoveObjects: 'conditionProps.canRemoveObjects',
            value: 'value',
            useCropper: 'conditionProps.useCropper',
            outputWidth: 'data.assetData.outputWidth',
            outputHeight: 'data.assetData.outputHeight',
            canSkipCropper: 'conditionProps.canSkipCropper',
            maxOutputWidth: 'data.assetData.maxOutputWidth',
            maxOutputHeight: 'data.assetData.maxOutputHeight'
        }
    });

    const { componentKey, backgroundRemoverState, initComponentKey, assetData } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            componentKey: 'componentKey',
            backgroundRemoverState: 'backgroundRemoverState',
            initComponentKey: 'initComponentKey',
            assetData: 'assetData'
        }
    });
    const [isSideBarRowOpen, setIsSideBarRowOpen] = useState(false);
    const [aiRows, setAiRows] = useState<AssetEditorSidebarRowProps[]>([]);
    const [transformRows, setTransformRows] = useState<AssetEditorSidebarRowProps[]>([]);

    /**
     * Callback function that sets the state of the isSideBarRowOpen variable.
     * Handles the init view of the asset editor.
     * @param openSideBarRow - A boolean value that determines whether the sidebar row is open or not.
     */
    const onSideBarRowOpen = (openSideBarRow: boolean) => {
        setIsSideBarRowOpen(openSideBarRow);

        // Handle init view of the asset editor only if the initComponentKey is not set.
        if (!initComponentKey || initComponentKey !== 'previewAsset') {
            handleInitView();
        }
    };

    /**
     * Returns an array of filtered rows based on the given boolean value.
     * @param isAiTool - A boolean value indicating whether the tool is an AI tool or not.
     * @returns An array of filtered rows.
     */
    const getFilteredRows = useCallback(
        (isAiTool: boolean) => {
            return assetEditorSidebarRows.filter((row) => {
                const setupHasAi = Setup.hasModule('AI');
                const fileType = AssetHelper.getFileType(value.extension);

                // Filter ai features if the setup does not have the AI module or if the feature is not enabled.
                if (row.isAiTool !== isAiTool || (row.isAiTool && !setupHasAi)) return false;

                if (row.componentKey === 'magicEditor') {
                    if (!canMagicEdit || fileType === 'video') {
                        return false;
                    }
                }

                if (row.componentKey === 'outpaint') {
                    if (!canOutpaint || !assetData || fileType === 'video') {
                        return false;
                    }
                }

                // Filter asset cropper for image and video type.
                if (row.componentKey === 'imageCropper' || row.componentKey === 'videoCropper') {
                    // Filter 'Crop' for image type.
                    if (row.componentKey === 'imageCropper' && fileType === 'video') {
                        return false;
                    }

                    // Filter 'Crop' for video type.
                    if (row.componentKey === 'videoCropper' && fileType !== 'video') {
                        return false;
                    }
                }

                // Filter flipper component when file type is a video.
                if (row.componentKey === 'flipper' && fileType === 'video') {
                    return false;
                }

                if ((row.componentKey === 'backgroundRemover' && !canRemoveBackground) || (row.componentKey === 'backgroundRemover' && fileType === 'video')) {
                    return false;
                }

                // Filter Object Remover remover.
                if ((row.componentKey === 'objectRemover' && !canRemoveObjects) || (row.componentKey === 'objectRemover' && fileType === 'video')) {
                    return false;
                }

                // Filer image compressor.
                if (row.componentKey === 'imageCompressor') {
                    return false;
                }

                // Filter cropper if the useCropper and outputWidth and outputHeight are not set.
                if (row.componentKey === 'imageCropper' && !useCropper && !outputWidth && !outputHeight) {
                    return false;
                }

                // Filter video cropper if the useCropper is not set.
                if (row.componentKey === 'videoCropper' && !useCropper) {
                    return false;
                }

                return true;
            });
        },
        [assetEditorSidebarRows, value, assetData, outputWidth, outputHeight, maxOutputWidth, maxOutputHeight]
    );

    /**
     * Returns an array of AI filtered rows with any modification that is necessary.
     */
    const getAiFilteredRows = useCallback(() => {
        const rows = [...getFilteredRows(true)];

        return rows.map((row) => {
            const rowCopy = { ...row };
            // check if background remover tool tip needs to be updated.
            if (rowCopy.componentKey === 'magicEditor' || rowCopy.componentKey === 'objectRemover') {
                if (backgroundRemoverState?.isBackgroundRemoved) {
                    rowCopy.toolTip = Translation.get('assetGalleryDialog.assetEditor.backgroundIsAlreadyRemoved', 'content-space');
                    rowCopy.isRowDisabled = true;
                }
            }

            return rowCopy;
        });
    }, [assetEditorSidebarRows, value, backgroundRemoverState, assetData]);

    const getExportRow = useCallback(
        (componentKey: AssetEditorComponentMapKeys) => {
            return assetEditorSidebarRows.find((row) => row.componentKey === componentKey) ?? assetEditorSidebarRows[0];
        },
        [assetEditorSidebarRows, componentKey]
    );

    /**
     * Checks whether the sidebar should be visible based on the number of AI rows and transform rows.
     * The sidebar should be hidden if there are no AI rows and no transform rows.
     * @returns {boolean} Whether the sidebar should be visible.
     */
    const checkSidebarVisibility = () => {
        return !(aiRows.length <= 0 && transformRows.length <= 0);
    };

    /**
     * Initializes the init view of the asset editor, if the initComponentKey is not set else it sets the initComponentKey to previewAsset.
     * The init view is only set on launch of the asset editor, after the user has finished with the init view, the init view is set to previewAsset.
     */
    const handleInitView = () => {
        if (!canSkipCropper && initComponentKey !== 'imageCropper' && initComponentKey !== 'videoCropper') {
            if (componentKey === 'imageCropper') {
                ComponentStoreHelpers.setModel('AssetEditor', 'initComponentKey', 'imageCropper');
            } else if (componentKey === 'videoCropper') {
                ComponentStoreHelpers.setModel('AssetEditor', 'initComponentKey', 'videoCropper');
            }
        } else {
            ComponentStoreHelpers.setModel<AssetEditorState, 'initComponentKey'>('AssetEditor', 'initComponentKey', 'previewAsset');
        }
    };

    useEffect(() => {
        if (componentKey === 'previewAsset') {
            onSideBarRowOpen(false); // All rows are closed.
        } else if (componentKey) {
            onSideBarRowOpen(true); // If component is key is not preview that means the sidebar row is open.
        }
    }, [componentKey]);

    useEffect(() => {
        setAiRows(getAiFilteredRows());
        setTransformRows(getFilteredRows(false));
    }, [backgroundRemoverState, assetData]);

    return checkSidebarVisibility() ? (
        <div className="asset-editor-side-bar">
            {!isSideBarRowOpen && transformRows.length > 0 && (
                <div className="asset-editor-side-bar__title">{Translation.get('assetGalleryDialog.assetEditor.transformOptions', 'content-space')}</div>
            )}
            {transformRows.map((row, index) => {
                return (
                    <AssetEditorSidebarRow
                        key={index}
                        {...row}
                        onRowOpen={onSideBarRowOpen}
                        isRowOpen={isSideBarRowOpen}
                        isInitView={row.componentKey === initComponentKey}
                    />
                );
            })}
            {!isSideBarRowOpen && aiRows.length > 0 && (
                <div className="asset-editor-side-bar__title">{Translation.get('assetGalleryDialog.assetEditor.smartAiTools', 'content-space')}</div>
            )}
            {aiRows.map((row, index) => {
                return (
                    <AssetEditorSidebarRow
                        key={index}
                        {...row}
                        onRowOpen={onSideBarRowOpen}
                        isRowOpen={isSideBarRowOpen}
                        isInitView={row.componentKey === initComponentKey}
                    />
                );
            })}
            {componentKey === 'imageCompressor' && (
                <AssetEditorSidebarRow {...getExportRow(componentKey)} onRowOpen={onSideBarRowOpen} isRowOpen={isSideBarRowOpen} />
            )}
        </div>
    ) : null;
};

export default AssetEditorSidebar;
