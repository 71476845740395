import React from 'react';
import GenericIcon from 'components/ui-components/GenericIcon';

interface Props {
    className?: string;
}

/**
 * A component that renders an SVG icon of a magic brush plus.
 * @param {string} props.className - The class name to apply to the SVG element.
 */
const MagicBrushPlusIcon: React.FC<Props> = ({ className }) => {
    return <GenericIcon icon="magic-brush-plus" svgClassName={className} />;
};

export default MagicBrushPlusIcon;
