import React from 'react';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';

import '../styles/stepper-loading.scss';

interface Props {
    onCancelClick?: () => void;
    text: string;
}

/**
 * A component that displays a loading indicator and a cancel button for the AssetEditorControllerStepper.
 * This component is used in the controller loading steps.
 * @param text - The text to display alongside the loading indicator.
 * @param onCancelClick - A function to call when the cancel button is clicked.
 */
const StepperLoading: React.FC<Props> = ({ text, onCancelClick }) => {
    return (
        <div className="asset-editor-stepper-loading">
            {text}
            {onCancelClick && (
                <Button
                    onClick={() => {
                        onCancelClick();
                    }}
                    variant="text">
                    {Translation.get('actions.cancel', 'common')}
                </Button>
            )}
        </div>
    );
};

export default StepperLoading;
