import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useEffect } from 'react';
import { ReactJSXElementType } from '@emotion/react/types/jsx-namespace';
import Icon from 'components/ui-components-v2/Icon';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import { TooltipCard, TooltipDetails } from 'components/ui-components/Tooltip';
import Tooltip from 'components/ui-components-v2/Tooltip';
import assetEditorComponentMap from '../../../data/asset-editor-component-map';
import { AssetEditorComponentMapKeys } from '../../../interfaces/AssetEditorComponentMap';
import AssetEditorIcon from '../../AssetEditorIcon';

import '../styles/asset-editor-side-bar-row.scss';

/**
 * The number of milliseconds to wait before showing the tooltip when one was already recently opened.
 */
const TOOLTIP_DELAY = 200;

interface Hint {
    imageSrc: string;
    title: string;
    description: string;
}

/** An interface representing a row in the Asset Editor sidebar. */
export interface AssetEditorSidebarRowProps {
    /** The icon to display in the sidebar row. */
    icon: string | ReactJSXElementType;
    /** The title to display in the sidebar row. */
    title: string;
    /** The key of the component in the AssetEditorComponentMap. */
    componentKey: AssetEditorComponentMapKeys;
    /** Whether the sidebar row is an AI tool. */
    isAiTool: boolean;
    /** An optional hint to display in the sidebar row. */
    hint?: Hint;
    /** Defines whether the mask editor is being used. */
    usesMaskEditor: boolean;
    /** Optional tool tip for the accordion summary. */
    toolTip?: string;
    /** Whether the sidebar row is disabled or not. */
    isRowDisabled?: boolean;
}

/** Props for the AssetEditorSidebarRow component. */
interface Props extends AssetEditorSidebarRowProps {
    /** Whether the sidebar row is currently open. */
    isRowOpen: boolean;
    /** A function to be called when the sidebar row is opened or closed. */
    onRowOpen?: (openSideBarRow: boolean) => void;
    /* Whether the sidebar row is the initial view. */
    isInitView?: boolean;
}

/**
 * A row in the Asset Editor sidebar that displays an icon, title, and optional hint.
 * When clicked, it expands to show a controller component for the corresponding asset editor component.
 */
const AssetEditorSidebarRow: React.FC<Props> = ({
    icon,
    title,
    componentKey: rowComponentKey,
    isAiTool,
    hint,
    onRowOpen,
    isRowOpen,
    isRowDisabled,
    toolTip,
    isInitView
}) => {
    const { componentKey } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: { componentKey: 'componentKey' }
    });

    const [expanded, setExpanded] = React.useState<AssetEditorComponentMapKeys | false>(componentKey); // The expanded state of the sidebar row.
    const ControllerComponent = assetEditorComponentMap[rowComponentKey].controllerComponent || null; // The controller component for the corresponding asset editor component.

    /** Sets the expanded state to false when the component key is not equal to the expanded value. */
    useEffect(() => {
        if (expanded !== componentKey) {
            setExpanded(false);
        }
    }, [componentKey]);

    /* Handles the change of the expanded state of the sidebar row. */
    const handleChange = (isExpanded: boolean) => {
        // If current view is the initial view, collapse the sidebar row, this means that the user has to complete certain required actions to go back.
        if (isInitView) {
            return;
        }

        if (isExpanded) {
            ComponentStoreHelpers.setData('AssetEditor', { componentKey: rowComponentKey }); // Goes to the corresponding asset editor component.
        } else {
            ComponentStoreHelpers.setModel('AssetEditor', 'componentKey', 'previewAsset'); // Goes back to preview asset component.
        }

        setExpanded(isExpanded ? rowComponentKey : false); // Expand or collapse the sidebar row depending on the current state.

        // This is needed to hide the other sidebar rows when one is expanded.
        if (onRowOpen) {
            onRowOpen(isExpanded);
        }
    };

    return (
        <Tooltip title={toolTip} placement="top" disableHoverListener={isRowOpen} disableFocusListener={isRowOpen}>
            <Accordion
                className={`asset-editor-side-bar-row ${
                    componentKey !== rowComponentKey && componentKey !== 'previewAsset' && 'asset-editor-side-bar-row-hide'
                }`}
                classes={{ root: 'accordion-root', expanded: 'accordion-expanded' }}
                expanded={componentKey === rowComponentKey} // The expanded state of the sidebar row.
                slotProps={{ transition: { unmountOnExit: true, timeout: 0 } }} // The unmountOnExit will unmount the controller component when the sidebar row is collapsed and the timeout is set to 0 to prevent the accordion from animating.
                disabled={isRowDisabled}
                onChange={(_, isExpanded) => handleChange(isExpanded)}>
                <AccordionSummary
                    classes={{ contentGutters: 'content-gutters' }}
                    className={`asset-editor-side-bar-row__summary ${isAiTool && !isRowOpen && 'asset-editor-side-bar-row__summary-ai'} ${
                        isRowOpen && 'asset-editor-side-bar-row-expanded__summary'
                    } ${isRowOpen && isInitView && 'asset-editor-side-bar-row-expanded__summary-no-hover'}`}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    {isRowOpen && !isInitView && <Icon className="asset-editor-side-bar-row-expanded__summary__back-icon">chevron_left</Icon>}
                    <AssetEditorIcon icon={icon} />
                    <div className="asset-editor-side-bar-row__summary__details">
                        <div className="asset-editor-side-bar-row__summary__details__title">{title}</div>
                    </div>
                    {hint && (
                        <TooltipCard
                            classes={{ tooltip: 'asset-editor-side-bar-row__summary__hint' }}
                            enterDelay={TOOLTIP_DELAY}
                            enterNextDelay={TOOLTIP_DELAY}
                            disableInteractive
                            title={
                                <TooltipDetails
                                    imageSrc={hint.imageSrc}
                                    title={hint.title}
                                    description={hint.description}
                                    classes={{
                                        title: 'asset-editor-side-bar-row__summary__hint-title',
                                        description: 'asset-editor-side-bar-row__summary__hint-description'
                                    }}
                                />
                            }>
                            <Icon className="icon">help</Icon>
                        </TooltipCard>
                    )}
                    {!isRowOpen && <Icon>keyboard_arrow_right</Icon>}
                </AccordionSummary>
                <AccordionDetails className="asset-editor-side-bar-row__details">{ControllerComponent && <ControllerComponent />}</AccordionDetails>
            </Accordion>
        </Tooltip>
    );
};

export default AssetEditorSidebarRow;
