import { useEffect } from 'react';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorStepperState from '../interfaces/AssetEditorStepperState';

/**
 * Hook to keep track and handle  the current asset editor stepper step state.
 */
const useAssetEditorStepperHook = () => {
    const { stepperStep } = useComponentStore<AssetEditorStepperState>('AssetEditorStepper', { fields: { stepperStep: 'stepperStep' } });

    /**
     * Moved unto the next step of the stepper.
     */
    const handleNext = () => {
        ComponentStoreHelpers.setData('AssetEditorStepper', { stepperStep: (stepperStep ? stepperStep : 0) + 1 });
    };

    /**
     * Goes back unto the previous step of the stepper.
     */
    const handleBack = () => {
        ComponentStoreHelpers.setData('AssetEditorStepper', { stepperStep: stepperStep - 1 });
    };

    /**
     * Set the state back to a specific step
     */
    const handleSetStep = (step: number) => {
        if (step < stepperStep) ComponentStoreHelpers.setData('AssetEditorStepper', { stepperStep: step });
    };

    /**
     * Resets the stepper to the initial state.
     */
    const handleReset = () => {
        ComponentStoreHelpers.setData('AssetEditorStepper', { stepperStep: 0 });
    };

    const defaultState = () => {
        ComponentStoreHelpers.setData('AssetEditorStepper', { stepperStep: 0 });
    };

    useEffect(() => {
        defaultState(); // Set initial state.
    }, []);

    return { handleNext, handleBack, handleSetStep, handleReset, stepperStep };
};

export default useAssetEditorStepperHook;
