import React from 'react';
import Slider from 'components/ui-components-v2/Slider';

import './styles/main.scss';

interface Props {
    sliderValue: number;
    title: string;
    onSliderChange: (event) => void;
    minSliderValue?: number;
    maxSliderValue?: number;
}

/**
 * A component that renders a slider row with a title and a slider.
 * @param sliderValue - The current value of the slider.
 * @param title - The title of the slider row.
 * @param onSliderChange - A function that is called when the slider value changes.
 */
const AssetEditorSliderRow: React.FC<Props> = ({ sliderValue, onSliderChange, title, minSliderValue = 1, maxSliderValue = 100 }) => {
    return (
        <div className="asset-editor-slider-row">
            <div className="asset-editor-slider-row__summary">
                <div className="asset-editor-slider-row__summary__text">{title}</div>
                <div className="asset-editor-slider-row__summary__text slider-row__summary__size">{sliderValue}</div>
            </div>

            <Slider
                className="asset-editor-slider-row__slider"
                min={minSliderValue}
                max={maxSliderValue}
                step={1}
                value={sliderValue}
                onChange={(event) => onSliderChange(event)}
            />
        </div>
    );
};

export default AssetEditorSliderRow;
