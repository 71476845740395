import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

import './styles/main.scss';

interface Classes {
    content?: string;
    title?: string;
}
interface Props {
    /**
     *  Loading text content
     */
    text: string;
    /**
     * If true, loading overlay will be shown.
     */
    isLoading?: boolean;
    /**
     * Class name to apply to the main div.
     */
    className?: string;
    /**
     * Classes for the inner components.
     */
    classes?: Classes;
}

/**
 * Overlay loading indicator wrapper to show circular progress over other components.
 */
const OverlayLoadingIndicatorWrapper: React.FC<PropsWithChildren<Props>> = ({ text, isLoading, children, className, classes }) => {
    return (
        <div className={classNames('overlay-loading-indicator', className)}>
            {isLoading && (
                <div className={classNames('overlay-loading-indicator__content', classes?.content)}>
                    <CircularProgress />
                    <div className={classNames('overlay-loading-indicator__content__title', classes?.title)}>{text}</div>
                </div>
            )}
            {children}
        </div>
    );
};

export default OverlayLoadingIndicatorWrapper;
