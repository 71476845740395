import Translation from 'components/data/Translation';
import { AssetEditorSidebarRowProps } from '../components/AssetEditorSidebar/components/asset-editor-side-bar-row';
import EraserIcon from '../icons/eraser-icon';
import MagicBrushPlusIcon from '../icons/magic-brush-plus-icon';
import backgroundRemover from '../images/background-remover.png';
import objectRemover from '../images/object-remover.png';
import magicEditor from '../images/magic-editor.png';
import outpaint from '../images/outpaint.png';

/**
 * An array of objects representing the rows in the asset editor sidebar.
 * Each object contains a title, icon, component key, and other properties.
 */
const assetEditorSidebarRows: AssetEditorSidebarRowProps[] = [
    {
        title: Translation.get('assetGalleryDialog.assetEditor.crop', 'content-space'),
        icon: 'crop',
        componentKey: 'imageCropper',
        isAiTool: false,
        usesMaskEditor: false
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.cropAndTrim', 'content-space'),
        icon: 'timelapse',
        componentKey: 'videoCropper',
        isAiTool: false,
        usesMaskEditor: false
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.rotateAndFlip', 'content-space'),
        icon: 'rotate_right',
        componentKey: 'flipper',
        isAiTool: false,
        usesMaskEditor: false
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.magicEdit', 'content-space'),
        icon: MagicBrushPlusIcon,
        componentKey: 'magicEditor',
        isAiTool: true,
        hint: {
            title: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.magicEditorTitle', 'content-space'),
            description: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.magicEditorDescription', 'content-space'),
            imageSrc: magicEditor
        },
        usesMaskEditor: true
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.outpaint', 'content-space'),
        icon: 'brush',
        componentKey: 'outpaint',
        isAiTool: true,
        usesMaskEditor: false,
        hint: {
            title: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.outpaintTitle', 'content-space'),
            description: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.outpaintDescription', 'content-space'),
            imageSrc: outpaint
        }
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.objectRemover', 'content-space'),
        icon: EraserIcon,
        componentKey: 'objectRemover',
        isAiTool: true,
        hint: {
            title: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.objectRemoverTitle', 'content-space'),
            description: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.objectRemoverDescription', 'content-space'),
            imageSrc: objectRemover
        },
        usesMaskEditor: true
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.backgroundRemover', 'content-space'),
        icon: 'texture',
        componentKey: 'backgroundRemover',
        isAiTool: true,
        hint: {
            title: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.backgroundRemoverTitle', 'content-space'),
            description: Translation.get('assetGalleryDialog.assetEditor.sidebar.hint.backgroundRemoverDescription', 'content-space'),
            imageSrc: backgroundRemover
        },
        usesMaskEditor: false
    },
    {
        title: Translation.get('assetGalleryDialog.assetEditor.export', 'content-space'),
        icon: 'tonality',
        componentKey: 'imageCompressor',
        isAiTool: false,
        usesMaskEditor: false
    }
];

export default assetEditorSidebarRows;
