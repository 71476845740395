import React from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Translation from 'components/data/Translation';
import assetEditorComponentMap from '../../data/asset-editor-component-map';
import AssetEditorState from '../../interfaces/AssetEditorState';

import './styles/main.scss';

/**
 * Renders the content of the asset editor.
 * @param {AssetGallery} props - The props for the asset gallery.
 */
const AssetEditorContent: React.FC = () => {
    const { componentKey, backgroundLoading = true } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: { componentKey: 'componentKey', backgroundLoading: 'backgroundLoading' }
    });

    const ContentComponent = componentKey ? assetEditorComponentMap[componentKey].contentComponent : null;

    return (
        <div className="asset-editor-content">
            {backgroundLoading && (
                <div className="asset-editor-content__loading">
                    <div className="asset-editor-content__loading__box">
                        <CircularProgress />
                        <div className="asset-editor-content__loading__box__title">
                            {Translation.get('assetGalleryDialog.assetEditor.loadingPreview', 'content-space')}
                        </div>
                    </div>
                </div>
            )}
            {ContentComponent && <ContentComponent />}
        </div>
    );
};

export default AssetEditorContent;
