import * as React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { PropsWithChildren } from 'react';
import StepConnector from '@mui/material/StepConnector';
import classNames from 'classnames';
import Stepper from 'components/ui-components-v2/Stepper';
import AssetEditorStepper from '../../interfaces/AssetEditorStepper';
import useAssetEditorStepperHook from '../../hooks/asset-editor-stepper-hook';
import AssetEditorAlertBox, { AssetEditorAlertBoxProps } from '../AssetEditorAlertBox';

import './styles/main.scss';

interface Props {
    /**
     * List of steps with is content.
     */
    steps: AssetEditorStepper[];
    /**
     * If true, then a loading text will be shown instead of the content.
     */
    isLoading?: boolean;
    /**
     * The loading children to display while loading.
     */
    loadingChildren?: React.ReactNode;
    /**
     * Alert box component for showing it above the description.
     */
    alertBox?: AssetEditorAlertBoxProps;
    /**
     * If true, then the step label click event will be disabled.
     */
    disableStepLabelClickEvent?: boolean;
}

/**
 * This component can be used for the asset editor controller stepper flow.
 * This component will loop through the provided list of steps and renders its content.
 */
const AssetEditorControllerStepper: React.FC<PropsWithChildren<Props>> = ({
    steps,
    children,
    isLoading,
    loadingChildren,
    alertBox,
    disableStepLabelClickEvent
}) => {
    const { stepperStep, handleSetStep } = useAssetEditorStepperHook();

    return (
        <div className="asset-editor-stepper">
            <Stepper activeStep={stepperStep} connector={<StepConnector classes={{ line: 'asset-editor-stepper__line' }} />} orientation="vertical">
                {steps.map(({ title, description }, index) => (
                    <Step key={title}>
                        <StepLabel
                            onClick={!disableStepLabelClickEvent ? () => handleSetStep(index) : undefined}
                            classes={{
                                root: 'asset-editor-stepLabel',
                                disabled: 'asset-editor-stepLabel',
                                active: 'asset-editor-stepLabel-active',
                                completed: classNames(
                                    'asset-editor-stepLabel-completed',
                                    disableStepLabelClickEvent ? 'asset-editor-stepLabel-completed-none-clickable' : ''
                                )
                            }}>
                            {title}
                        </StepLabel>
                        <StepContent classes={{ root: 'asset-editor-stepper__line' }}>
                            {!isLoading && (
                                <>
                                    {alertBox && <AssetEditorAlertBox title={alertBox.title} subTitle={alertBox.subTitle} type={alertBox.type} />}
                                    <div className="asset-editor-stepper__description">{description}</div>
                                    <div className="asset-editor-stepper__content">{children}</div>
                                </>
                            )}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

            {isLoading && <div className="asset-editor-stepper__loading">{loadingChildren}</div>}
        </div>
    );
};

export default AssetEditorControllerStepper;
