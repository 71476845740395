import React from 'react';
import Alert from 'components/ui-components-v2/Alert';

import AlertBoxType from 'components/ui-components/AlertBox/interfaces/alert-box-type';

import './styles/main.scss';

export interface AssetEditorAlertBoxProps {
    title: string;
    subTitle?: string;
    type: AlertBoxType;
}

/**
 * A component that displays an alert box with a title and subtitle for the asset editor controllers.
 * @param title - The title of the alert box.
 * @param subTitle - The subtitle of the alert box.
 * @param type - The type of the alert box (e.g. "success", "warning", "error").
 */
const AssetEditorAlertBox: React.FC<AssetEditorAlertBoxProps> = ({ title, subTitle, type }) => {
    return (
        <Alert className="asset-editor-alert" severity={type}>
            <div className="asset-editor-alert__content-box">
                <div className="asset-editor-alert__content-box__title">{title}</div>
                <div className="asset-editor-alert__content-box__subtitle">{subTitle}</div>
            </div>
        </Alert>
    );
};

export default AssetEditorAlertBox;
