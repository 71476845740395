import React, { useEffect } from 'react';
import ImageFileService from 'services/image-file/image.service';
import Dark from 'components/ui-components/Dark';
import ComponentStore from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetHelper from 'helpers/asset.helper';
import AssetEditorContent from './components/AssetEditorContent';
import AssetEditorSidebar from './components/AssetEditorSidebar';
import { AssetEditorComponentMapKeys } from './interfaces/AssetEditorComponentMap';
import AssetEditorHelper from './helpers/asset-editor-helper';
import { AssetDimensions } from '../AssetGalleryDialog/interfaces/AssetGalleryData';
import AssetEditorState from './interfaces/AssetEditorState';
import AssetGalleryDialogState from '../AssetGalleryDialog/interfaces/AssetGalleryDialogState';

import './styles/main.scss';

interface AssetEditorStateProps {
    value: AssetGalleryDialogState['value'];
    useCropper: AssetGalleryDialogState['conditionProps']['useCropper'];
    canSkipCropper: AssetGalleryDialogState['conditionProps']['canSkipCropper'];
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    initComponentKey: AssetEditorComponentMapKeys;
}

/**
 * AssetEditor component is responsible for rendering the asset editor UI and managing the state of the asset editor.
 */
const AssetEditor: React.FC = () => {
    const { value, useCropper, canSkipCropper, outputWidth, outputHeight, initComponentKey } = useComponentStore<AssetEditorStateProps>('AssetGalleryDialog', {
        fields: {
            value: 'value',
            useCropper: 'conditionProps.useCropper',
            canSkipCropper: 'conditionProps.canSkipCropper',
            outputWidth: 'data.assetData.outputWidth',
            outputHeight: 'data.assetData.outputHeight',
            initComponentKey: 'config.initComponentKey'
        }
    });

    const setAssetEditorForVideo = (componentKey: AssetEditorComponentMapKeys) => {
        ComponentStore.setData('AssetEditor', {
            originalAssetSrc: value.originalVideo,
            modifiedAssetSrc: '',
            componentKey: componentKey,
            previewAssetSrc: '',
            videoCropperState: {
                cropData: value?.cropData ? { ...value?.cropData } : null,
                croppedModifiedAssetSrc: value.url
            }
        });
    };

    const setAssetEditorForImage = (
        originalAssetSrc: string,
        modifiedAssetSrc: string,
        componentKey: AssetEditorComponentMapKeys,
        imageDimensions: AssetDimensions | undefined
    ) => {
        ComponentStore.setData<AssetEditorState>('AssetEditor', {
            originalAssetSrc: originalAssetSrc,
            modifiedAssetSrc: modifiedAssetSrc,
            croppedModifiedAssetSrc: '',
            componentKey: componentKey,
            previewAssetSrc: '',
            imageCropperState: {
                selectedAspectRatio: '',
                cropData: value.crop ? { ...value.crop } : undefined,
                croppedModifiedAssetSrc: ''
            },
            outpaintState: {
                selectedAspectRatio: '',
                outpaintData: undefined,
                isResetTriggered: false,
                croppedModifiedAssetSrc: '',
                processedMaxOutputHeight: 0,
                processedMaxOutputWidth: 0
            },
            assetFlipperState: value.assetFlipperState,
            imageCompressorState: value.imageCompressorState,
            backgroundRemoverState: value.backgroundRemoverState,
            initComponentKey: undefined,
            loading: false,
            assetData: imageDimensions ? { width: imageDimensions.width, height: imageDimensions.height } : { width: 0, height: 0 }
        });
    };

    const setDefaultStateToComponentStore = async () => {
        const componentKey = AssetEditorHelper.getComponentKey(canSkipCropper, useCropper, value.extension, outputWidth, outputHeight, initComponentKey);

        if (AssetHelper.getFileType(value.extension) === 'video') {
            if (!value.originalVideo) {
                value.originalVideo = value.url;
            }

            setAssetEditorForVideo(componentKey);
        } else {
            const originalAssetSrc = value.originalImage || value.url;
            const assetUrls = [originalAssetSrc, value.modifiedAsset];

            const [originalAssetBase64, modifiedAssetBase64] = await ImageFileService.convertAssetUrlsToBase64(assetUrls);

            let imageDimensions: AssetDimensions | undefined = value.assetData;

            // Only calculate the image dimensions if the asset data is undefined.
            if (!imageDimensions) {
                imageDimensions = originalAssetBase64 ? await ImageFileService.getBase64ImageDimensions(originalAssetBase64) : undefined;
            }

            setAssetEditorForImage(originalAssetBase64 ?? '', modifiedAssetBase64 ?? '', componentKey, imageDimensions);
        }
    };

    useEffect(() => {
        if (value?.originalAsset || value?.url || value?.originalImage || value?.originalVideo) {
            setDefaultStateToComponentStore();
        }

        return () => {
            ComponentStore.remove('AssetEditor');
        };
    }, []);

    return (
        <Dark dark={true}>
            <div className="asset-editor" data-mui-color-scheme="dark">
                <AssetEditorContent />
                <AssetEditorSidebar />
            </div>
        </Dark>
    );
};

export default AssetEditor;
