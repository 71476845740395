import React, { PropsWithChildren, ReactElement } from 'react';

import './styles/main.scss';

interface Props {
    className?: string;
}

/**
 * This component is being used to align asset editor actions into a row.
 */
const AssetEditorActionRow: React.FC<PropsWithChildren<Props>> = ({ children, className }) => {
    return (
        <div className={`asset-editor-actions-row ${className}`}>
            {React.Children.map(children, (child) => {
                const item = child as ReactElement<PropsWithChildren<{ className: string }>>;

                if (React.isValidElement(item)) {
                    return React.cloneElement(item, { className: 'asset-editor-actions-row__action' });
                }
                return child;
            })}
        </div>
    );
};

export default AssetEditorActionRow;
