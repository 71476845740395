import Icon from 'components/ui-components-v2/Icon';
import React from 'react';
import { ReactJSXElementType } from '@emotion/react/types/jsx-namespace';

import './styles/main.scss';

interface Props {
    icon: string | ReactJSXElementType;
}

/**
 * Renders an icon component for the asset editor.
 * @param {string | ReactJSXElementType} icon - The icon to be rendered.
 */
const AssetEditorIcon: React.FC<Props> = ({ icon: IconComponent }) => {
    return typeof IconComponent === 'string' ? <Icon className="asset-editor-icon">{IconComponent}</Icon> : <IconComponent className={'asset-editor-icon'} />;
};

export default AssetEditorIcon;
