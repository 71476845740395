import React, { PropsWithChildren, useEffect } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import Translation from 'components/data/Translation';
import AssetEditorState from '../../interfaces/AssetEditorState';

import './styles/main.scss';

interface Props {
    imageSrc?: string;
    imageBoxStyle?: React.CSSProperties;
    imageClassName?: string;
    mainDivClassName?: string;
    imageRef?: React.RefObject<HTMLImageElement>;
    onLoad?: (event) => void;
}

/**
 * A component for displaying an image in the asset editor.
 * @param imageSrc The source of the image to display.
 * @param imageBoxStyle Additional styles to apply to the image container.
 * @param imageClassName Additional classes to apply to the image element.
 * @param mainDivClassName Additional classes to apply to the main container div.
 * @param imageRef A ref object for the image element.
 * @param children Child elements to render below the image.
 * @param onLoad A callback function to execute when the image has finished loading.
 */
const AssetEditorImage: React.FC<PropsWithChildren<Props>> = ({ imageSrc, imageBoxStyle, imageClassName, mainDivClassName, imageRef, children, onLoad }) => {
    const { assetFlipperState } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: { assetFlipperState: 'assetFlipperState' }
    });

    useEffect(() => {
        return () => {
            const assetEditor: AssetEditorState | undefined = ComponentStoreHelpers.get('AssetEditor');

            // Reset the background loading state when the component is unmounted.
            if (assetEditor && assetEditor.backgroundLoading) {
                ComponentStoreHelpers.setModel('AssetEditor', 'backgroundLoading', true); // Resets asset preview src.
            }
        };
    }, []);

    /** Make sure the background loading is set to false when image is loaded and then call the onLoad callback.*/
    const onImageLoad = (event: unknown) => {
        ComponentStoreHelpers.setModel('AssetEditor', 'backgroundLoading', false); // Resets asset preview src.

        if (onLoad) {
            onLoad(event);
        }
    };

    return (
        <div className={`asset-editor-content-image ${mainDivClassName}`}>
            <div
                className="asset-editor-content-image__content-box"
                style={{
                    transform: `rotate(${assetFlipperState?.rotationAngle}deg) scaleX(${assetFlipperState?.scaleX}) scaleY(${assetFlipperState?.scaleY})`,
                    ...imageBoxStyle
                }}>
                {imageSrc && (
                    <img
                        id="asset-editor-content-image-id"
                        ref={imageRef}
                        crossOrigin="anonymous"
                        className={`asset-editor-content-image__image ${imageClassName}`}
                        src={imageSrc}
                        alt={Translation.get('assetGalleryDialog.assetEditor.assetImage', 'content-space')}
                        onLoad={onImageLoad}
                    />
                )}
            </div>
            {children}
        </div>
    );
};

export default AssetEditorImage;
