import React, { PropsWithChildren } from 'react';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import AssetEditorActionRow from '../AssetEditorActionsRow/asset-editor-actions';

interface Props {
    /** A function to call when the "Cancel" button is clicked.*/
    onCancel: () => void;
    /** A function to call when the "Apply" button is clicked. */
    onApply: () => void;
}

/**
 * Component that displays the review step of the asset editor controller stepper.
 */
const AssetEditorReviewStep: React.FC<PropsWithChildren<Props>> = ({ children, onCancel, onApply }) => {
    return (
        <div>
            <AssetEditorActionRow>
                <Button
                    onClick={() => {
                        onCancel();
                    }}
                    variant="text">
                    {Translation.get('actions.cancel', 'common')}
                </Button>
                <Button
                    onClick={() => {
                        onApply();
                    }}
                    variant="contained"
                    color="primary">
                    {Translation.get('actions.apply', 'common')}
                </Button>
            </AssetEditorActionRow>
            {children}
        </div>
    );
};

export default AssetEditorReviewStep;
