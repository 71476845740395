import ImageCropperController from 'components/assets/AssetGalleryCropper/components/image-cropper-wrapper/components/image-cropper-controller';
import AssetFlipperController from 'components/assets/AssetFlipper/components/asset-flipper-controller';
import AssetFlipper from 'components/assets/AssetFlipper';
import AssetMaskEditor from 'components/assets/AssetMaskEditor';
import AssetEditorPreview from 'components/assets/AssetEditorPreview';
import VideoCropperController from 'components/assets/AssetGalleryCropper/components/video-cropper-wrapper/components/video-cropper-controller';
import VideoCropperWrapper from 'components/assets/AssetGalleryCropper/components/video-cropper-wrapper';
import ImageCropperWrapper from 'components/assets/AssetGalleryCropper/components/image-cropper-wrapper';
import AssetTransformsPreview from 'components/assets/AssetTransformsPreview';
import AssetBackgroundRemoverController from 'components/assets/AssetEditorControllers/components/AssetBackgroundRemover';
import AssetObjectRemoverController from 'components/assets/AssetEditorControllers/components/AssetObjectRemover';
import AssetMagicEditorController from 'components/assets/AssetEditorControllers/components/AssetMagicEditor';
import AssetOutpaintController from 'components/assets/AssetEditorControllers/components/AssetOutpaint';
import ImageCompressorController from 'components/assets/AssetEditorControllers/components/ImageCompressor';
import { AssetEditorComponentMap } from '../interfaces/AssetEditorComponentMap';

/**
 * A map of asset editor components, where each key is a string representing the name of the component,
 * and each value is an object with a contentComponent and controllerComponent property.
 * The contentComponent is the React component that renders the asset editor UI, and the controllerComponent
 * is the React component that handles the logic for the asset editor.
 */
const assetEditorComponentMap: AssetEditorComponentMap = {
    previewAsset: { contentComponent: AssetTransformsPreview, controllerComponent: null },
    imageCropper: { contentComponent: ImageCropperWrapper, controllerComponent: ImageCropperController },
    videoCropper: { contentComponent: VideoCropperWrapper, controllerComponent: VideoCropperController },
    flipper: { contentComponent: AssetFlipper, controllerComponent: AssetFlipperController },
    backgroundRemover: { contentComponent: AssetEditorPreview, controllerComponent: AssetBackgroundRemoverController },
    objectRemover: { contentComponent: AssetMaskEditor, controllerComponent: AssetObjectRemoverController },
    magicEditor: { contentComponent: AssetMaskEditor, controllerComponent: AssetMagicEditorController },
    outpaint: { contentComponent: ImageCropperWrapper, controllerComponent: AssetOutpaintController },
    imageCompressor: { contentComponent: AssetEditorPreview, controllerComponent: ImageCompressorController }
};

export default assetEditorComponentMap;
